
import { Options, Vue } from 'vue-class-component'
import theCompetition from '@/components/Frontend/Competition/index.vue'

@Options({
  components: {
    theCompetition
  }
})

export default class Competition extends Vue {}
