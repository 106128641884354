import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class RegistrationFeeDataService extends APIBaseFunctions {
  private cachedRegistrationFeeDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = RegistrationFeeDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedRegistrationFeeDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/tilmeldingsgebyrs?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/tilmeldingsgebyrs?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/tilmeldingsgebyrs/${id}`)
  }

  getCount () {
    return http.get('/tilmeldingsgebyrs/count')
  }

  create (data: any) {
    return http.post('/tilmeldingsgebyrs', data)
  }

  update (id: string, data: any) {
    return http.put(`/tilmeldingsgebyrs/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/tilmeldingsgebyrs/${id}`)
  }

  deleteAll () {
    return http.delete('/tilmeldingsgebyrs')
  }

  findByRegistrationFeeType (type: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = RegistrationFeeDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedRegistrationFeeDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/tilmeldingsgebyrs?registration_type=${type}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }
}

export default new RegistrationFeeDataService()
